<template>
  <b-row class="match-height">
    <b-col>
      
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BCard, BCardTitle, BCardBody, BCardHeader, BDropdown, BDropdownItem, BCardSubTitle,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardBody,
    BCardHeader,
    BDropdown,
    BDropdownItem,
    BCardSubTitle,
    
  },
  data() {
    return {
      
    }
  },
  created() {
    
  },
}
</script>

<style lang="scss">
// @import '@core/scss/vue/libs/vue-flatpicker.scss';
// @import '@core/scss/vue/pages/dashboard-ecommerce.scss';
// @import '@core/scss/vue/libs/chart-apex.scss';
</style>
